import React, { useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import html2canvas from "html2canvas"
import Canvg, { presets } from "canvg"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { useQueryParam, StringParam } from "use-query-params"
import AdsenseResponsive from "../../components/adsenseResponsive"

const FeaturedImageGenerator = () => {
  const OFF_SCREEN_CANVAS_ID = "svgOffScreeenRenderCanvas"
  const OFF_SCREEN_CANVAS_CLASS = "svg-off-screen-render-canvas"

  const intl = useIntl()
  const [pngIconData, setPngIconData] = useState("")
  const [pngShadowData, setPngShadowData] = useState("")
  const [pngEffectData, setPngEffectData] = useState("")
  const [isIconRendered, setIsIconRendered] = useState(false)
  const [isShadowRendered, setIsShadowRendered] = useState(false)
  const [isEffectRendered, setIsEffectRendered] = useState(false)
  const [isEffectCircle, setIsEffectCircle] = useState(true)
  const [inputText1, setInputText1] = useState("")
  const [inputText2, setInputText2] = useState("")
  const [svgIconText, setSvgIconText] = useState("")
  const [svgEffectText, setSvgEffectText] = useState("")
  const [currentEffect, setCurrentEffect] = useState("circle")
  const [currentIconName, setCurrentIconName] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [allSvgIcons, setAllSvgIcons] = useState([])
  const [resultArr, setResultArr] = useState([])
  const [queryIcon, setQueryIcon] = useQueryParam("icon", StringParam)
  const [isResultModalOpen, setIsResultModalOpen] = useState(false)
  const [resultPngData, setResultPngData] = useState("")

  const colorPallete = [
    "#DCDCCF",
    "#C35B5D",
    "#DC965F",
    "#F0CB87",
    "#74BEAE",
    "#75B0D2",
    "#9380B5",
    "#4E5C73",
    "#FFFFFF",
  ]

  const useInput = initialValue => {
    const [value, set] = useState(initialValue)
    return {
      value,
      onChange: e => {
        set(e.target.value)
        changeIconColor(e.target.value)
      },
    }
  }

  const pickedColor = useInput("")

  //アイコン本体の色変更
  const changeIconColor = newColor => {
    const regex = /<circle cx="100" cy="100" r="100" fill=".*?" ?\/>/gi
    const after = '<circle cx="100" cy="100" r="100" fill="' + newColor + '"/>'
    const newSvgText = svgIconText.replace(regex, after)
    svg2png(newSvgText, 1280, 1280, "icon")
    setSvgIconText(newSvgText)
  }

  //ダウンロードボタン押下時の画像生成とダウンロード
  function capture() {
    window.scrollTo(0, 0)
    const elm = document.querySelector("#capture")
    html2canvas(elm, {
      scale: 4,
      logging: false,
    }).then(function (canvas) {
      var dataUrl = canvas.toDataURL("image/png")
      var a = document.createElement("a")
      a.href = dataUrl
      setResultPngData(dataUrl)
      setIsResultModalOpen(true)
    })
  }

  //マウントのタイミングで一度だけ実行される
  useEffect(() => {
    if (queryIcon == null || queryIcon === "") {
      fetchSvg("rocket_1")
      setCurrentIconName("rocket_1")
    } else {
      fetchSvg(queryIcon)
      setCurrentIconName(queryIcon)
    }
    renderShadow()
    fetchEffect("featured-image-effect-circle")
    changeBoxColor("#DCDCCF")
    changeCaptureTextColor("#4E5C73")
    setInitialText()
  }, [])

  //エフェクトの描画
  function fetchEffect(name) {
    fetch("/images/tools/" + name + ".svg")
      .then(response => response.text())
      .then(resultData => {
        setSvgEffectText(resultData)
        svg2png(resultData, 1040, 720, "effect")
      })
  }

  //エフェクトの種類変更
  function changeEffect(effectType) {
    setCurrentEffect(effectType)
    if (effectType === "none") {
      setIsEffectRendered(false)
    } else {
      setIsEffectRendered(false)
      setIsEffectCircle(effectType === "circle")
      fetchEffect("featured-image-effect-" + effectType)
    }
  }

  //エフェクトの色変更
  function changeEffectColor(newColor) {
    const regex = /style="fill: #[a-zA-Z0-9]{6}"/i
    const after = 'style="fill: ' + newColor + '"'
    const newSvgText = svgEffectText.replace(regex, after)
    svg2png(newSvgText, 1040, 720, "effect")
    setSvgEffectText(newSvgText)
  }

  //アイコンshadowの描画
  function renderShadow() {
    fetch("/images/tools/featured-image-icon-shadow.svg")
      .then(response => response.text())
      .then(resultData => {
        svg2png(resultData, 1264, 640, "shadow")
      })
  }

  //指定したアイコン画像の取得とpng変換呼び出し
  function fetchSvg(name) {
    fetch("/images/icons/" + name + ".svg")
      .then(response => response.text())
      .then(resultData => {
        setSvgIconText(resultData)
        svg2png(resultData, 1280, 1280, "icon")
      })
  }

  //SVGからPNGへの変換をしてicon, shadow, effectのtargetに応じてpngデータをセット
  const svg2png = async (text, width, height, target) => {
    const canvas = createOffScreenCanvas(width, height)
    const ctx = canvas.getContext("2d")
    const v = await Canvg.from(ctx, text, presets.offscreen())
    await v.render()
    let data = canvas.toDataURL("image/png")
    if (target === "icon") {
      setPngIconData(data)
      setIsIconRendered(true)
    } else if (target === "shadow") {
      setPngShadowData(data)
      setIsShadowRendered(true)
    } else if (target === "effect") {
      setPngEffectData(data)
      setIsEffectRendered(true)
    }
    canvas.remove()
  }

  //キャンバスの作成
  function createOffScreenCanvas(width, height) {
    var newCanvas = document.createElement("canvas")
    newCanvas.setAttribute("id", OFF_SCREEN_CANVAS_ID)
    newCanvas.setAttribute("width", width)
    newCanvas.setAttribute("height", height)

    //styleの設定
    var style = newCanvas.style
    style.position = "absolute"
    style.left = "-9999px"
    style.top = "0px"

    newCanvas.classList.add(OFF_SCREEN_CANVAS_CLASS)
    document.querySelector("body").appendChild(newCanvas)
    return newCanvas
  }

  //アイキャッチの全体背景色を変更
  function changeBoxColor(newColor) {
    document.getElementById("whole-bg").style.backgroundColor = newColor
  }

  //アイキャッチの文字色を変更
  function changeCaptureTextColor(newColor) {
    document.getElementById("whole-bg").style.color = newColor
  }

  function setInitialText() {
    setInputText1(intl.formatMessage({ id: "tools.generator.initialTitle1" }))
    setInputText2(intl.formatMessage({ id: "tools.generator.initialTitle2" }))
  }

  function fetchAllSvgIcons() {
    fetch("/data/svgItems.json")
      .then(response => response.json())
      .then(resultData => {
        const allIcons = resultData.filter(item => item.cat === "icons")
        setAllSvgIcons(allIcons.reverse())
        setResultArr(allIcons)
      })
  }

  function seachSvgIcons(tagName) {
    const arr = []
    if (tagName === "") {
      setResultArr(allSvgIcons)
    } else {
      for (const k in allSvgIcons) {
        if (allSvgIcons[k].tag.indexOf(tagName.toLowerCase()) !== -1) {
          arr.push(allSvgIcons[k])
        }
      }
      setResultArr(arr)
    }
  }

  function modalOpen() {
    setIsModalOpen(true)
    // アイコン画像取得処理
    fetchAllSvgIcons()
  }

  function changeSvgIcon(name) {
    fetchSvg(name)
    setCurrentIconName(name)
    setIsModalOpen(false)
  }

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "tools.featuredImageGen" })}
        image="https://flat-svg-designs.net/images/tools/featured_image.png"
        description={intl.formatMessage({ id: "tools.featuredImageGenDesc" })}
      />
      <section className="w-full md:w-3/4 flex flex-col px-3">
        <h3 className="font-semibold mb-3 text-xl leading-tight sm:leading-normal">
          {intl.formatMessage({ id: "tools.featuredImageGen" })}
        </h3>
        <div className={isModalOpen ? "relative" : "relative hidden"}>
          <div className="absolute z-40 modal-window">
            <div className="mx-4 sm:mx-20 p-3 h-full bg-white border border-gray-400 border rounded">
              <div className="flex justify-between">
                <div className="font-semibold mb-3 text-lg leading-tight sm:leading-normal">
                  {intl.formatMessage({ id: "tools.generator.selectIcon" })}
                </div>
                <button
                  className="block h-6 w-6 fill-current"
                  onClick={() => setIsModalOpen(false)}
                >
                  <svg viewBox="0 0 24 24">
                    <path
                      fillRule="evenodd"
                      d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                    />
                  </svg>
                </button>
              </div>
              <span className="relative">
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full bg-gray-200 text-sm text-gray-700 border border-gray-400 focus:outline-none focus:border-gray-500 rounded py-1 px-2 leading-normal appearance-none"
                  onChange={e => seachSvgIcons(e.target.value)}
                />
              </span>
              <div className="overflow-auto h-48 sm:h-64 bg-gray-200 p-3 mt-3 rounded border border-gray-400">
                <div className="images grid grid-cols-4 gap-2 sm:grid-cols-5 md:grid-cols-8 md:gap-3">
                  {resultArr.map((item, index) => (
                    <div
                      key={index}
                      className="image bg-white rounded border border-gray-400 overflow-hidden hover:shadow cursor-pointer"
                      onClick={() => changeSvgIcon(item.name)}
                    >
                      <img
                        src={"/images/" + item.cat + "/" + item.name + ".svg"}
                        alt={item.tag}
                        className="m-1"
                        loading="lazy"
                      ></img>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={isResultModalOpen ? "relative" : "relative hidden"}>
          <div className="absolute z-50 modal-result-window">
            <div className="mx-4 sm:mx-20 p-3 h-full bg-white border border-gray-400 border rounded">
              <div className="flex justify-between">
                <div className="font-semibold mb-3 text-lg leading-tight sm:leading-normal">
                  <span>
                    {intl.formatMessage({ id: "tools.generator.complete" })}
                  </span>
                  <span className="hidden md:inline">(2400×1260px)</span>
                  <span className="inline md:hidden">(1200×630px)</span>
                </div>
                <button
                  className="block h-6 w-6 fill-current"
                  onClick={() => setIsResultModalOpen(false)}
                >
                  <svg viewBox="0 0 24 24">
                    <path
                      fillRule="evenodd"
                      d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                    />
                  </svg>
                </button>
              </div>
              <p className="text-sm pb-2 hidden md:block">
                {intl.formatMessage({ id: "tools.generator.downloadDesc" })}
              </p>
              <p className="text-sm pb-2 block md:hidden">
                {intl.formatMessage({ id: "tools.generator.downloadDescSp" })}
              </p>
              <div className="">
                <img src={resultPngData}></img>
              </div>
              <div className="pt-3 flex justify-center">
                <a
                  download={
                    "flat-svg-designs-featured-image-" +
                    currentIconName +
                    ".png"
                  }
                  href={resultPngData}
                >
                  <button className="w-64 h-12 cursor-pointer bg-teal-600 hover:bg-teal-500 text-teal-100 py-2 px-4 rounded inline-flex justify-center items-center border border-gray-400">
                    <svg
                      className="fill-current w-4 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                    </svg>
                    <p>{intl.formatMessage({ id: "download.download" })}</p>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full h-full bg-white rounded border border-gray-400 relative">
          <div className="absolute w-full ">
            <div className="mt-3 p-2 pl-4 w-24 bg-gray-800 text-lg text-gray-100 rounded-r border border-gray-400 border-l-0">
              Preview
            </div>
          </div>
          <div className="my-6" id="capture">
            <div
              className="capture flex flex-col items-center justify-center"
              id="whole-bg"
            >
              <div className="capture-img mt-2">
                <img
                  src={pngEffectData}
                  className={
                    isEffectRendered
                      ? isEffectCircle
                        ? "effect-circle"
                        : "effect-star"
                      : "hidden"
                  }
                  alt="effect data"
                ></img>
                <img
                  src={pngShadowData}
                  className={isShadowRendered ? "effect-shadow" : "invisible"}
                  alt="shadow data"
                ></img>
                <div className="capture-icon">
                  <img
                    src={pngIconData}
                    className={isIconRendered ? "" : "invisible"}
                    alt="main data"
                  ></img>
                </div>
              </div>
              <span className="mt-3 capture-text font-bold">{inputText1}</span>
              <span className="capture-text font-bold">{inputText2}</span>
            </div>
          </div>
        </div>
        <div className="container mb-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-0 sm:gap-6 mb-6 md:mb-3">
            <div className="w-full h-full">
              <div className="text-sm w-full mt-2">
                {intl.formatMessage({ id: "tools.generator.selectIcon" })}
              </div>
              <div className="flex mb-3">
                <input
                  type="text"
                  disabled="disabled"
                  className="block w-40 bg-gray-300 text-gray-700 border border-gray-400 rounded p-2 my-2 appearance-none"
                  value={currentIconName}
                ></input>
                <button
                  onClick={() => modalOpen()}
                  className="bg-teal-600 hover:bg-teal-500 text-teal-100 p-2 my-2 ml-3 rounded inline-flex justify-center items-center border border-gray-400"
                >
                  <svg
                    className="fill-current w-4 h-4 mx-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
                  </svg>
                </button>
              </div>
              <div className="text-sm w-full mt-2">
                {intl.formatMessage({
                  id: "tools.generator.selectIconBgColor",
                })}
              </div>
              <div className="flex h-10 mb-3">
                {colorPallete.map(color => (
                  <label key={color} className="cursor-pointer p-1">
                    <input
                      {...pickedColor}
                      type="radio"
                      value={color}
                      className="hidden"
                      name="selectColor"
                    />
                    <div className="rounded-full border border-gray-400 hover:shadow">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 91 91"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="45.141"
                          cy="45.141"
                          r="45.141"
                          style={{ fill: color }}
                        />
                      </svg>
                    </div>
                  </label>
                ))}
              </div>
              {/* エフェクトの選択 */}
              <div className="text-sm w-full mt-2">
                {intl.formatMessage({ id: "tools.generator.selectEffectType" })}
              </div>
              <div className="flex h-10 mb-3">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio"
                    name="effectType"
                    value="circle"
                    onChange={e => changeEffect(e.target.value)}
                    checked={currentEffect === "circle"}
                  ></input>
                  <span className="ml-2">
                    {intl.formatMessage({ id: "tools.generator.effectBubble" })}
                  </span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    className="form-radio"
                    name="effectType"
                    value="star"
                    onChange={e => changeEffect(e.target.value)}
                    checked={currentEffect === "star"}
                  ></input>
                  <span className="ml-2">
                    {intl.formatMessage({ id: "tools.generator.effectStar" })}
                  </span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    className="form-radio"
                    name="effectType"
                    value="none"
                    onChange={e => changeEffect(e.target.value)}
                    checked={currentEffect === "none"}
                  ></input>
                  <span className="ml-2">
                    {intl.formatMessage({ id: "tools.generator.effectNone" })}
                  </span>
                </label>
              </div>
              {/* エフェクトの色選択 */}
              <div className="text-sm w-full mt-2">
                {intl.formatMessage({
                  id: "tools.generator.selectEffectColor",
                })}
              </div>
              <div className="flex h-10 mb-3">
                {colorPallete.map(color => (
                  <label key={color} className="cursor-pointer p-1">
                    <input
                      onClick={e => changeEffectColor(e.target.value)}
                      type="radio"
                      value={color}
                      className="hidden"
                      name="selectBgColor"
                    />
                    <div className="rounded-full border border-gray-400 hover:shadow">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 200 200"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="100"
                          cy="100"
                          r="100"
                          style={{ fill: color }}
                        />
                      </svg>
                    </div>
                  </label>
                ))}
              </div>
              {/* 全体の背景色 */}
              <div className="text-sm w-full mt-2">
                {intl.formatMessage({ id: "tools.generator.selectBgColor" })}
              </div>
              <div className="flex h-10 mb-3">
                {colorPallete.map(color => (
                  <label key={color} className="cursor-pointer p-1">
                    <input
                      onClick={e => changeBoxColor(e.target.value)}
                      type="radio"
                      value={color}
                      className="hidden"
                      name="selectBgColor"
                    />
                    <div className="rounded-full border border-gray-400 hover:shadow">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 200 200"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="100"
                          cy="100"
                          r="100"
                          style={{ fill: color }}
                        />
                      </svg>
                    </div>
                  </label>
                ))}
              </div>
            </div>
            <div className="w-full h-full">
              <div className="text-sm w-full mt-2">
                {intl.formatMessage({ id: "tools.generator.inputTitle" })}
              </div>
              <div className="my-2">
                <input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "tools.generator.inputRow1",
                  })}
                  onChange={e => setInputText1(e.target.value)}
                  id="inputText1"
                  className="block w-full bg-white text-gray-700 border border-gray-400 rounded p-2 my-2 focus:outline-none focus:border-gray-500 appearance-none"
                  value={inputText1}
                ></input>
                <input
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "tools.generator.inputRow2",
                  })}
                  onChange={e => setInputText2(e.target.value)}
                  id="inputText2"
                  className="block w-full bg-white text-gray-700 border border-gray-400 rounded p-2 my-2 focus:outline-none focus:border-gray-500 appearance-none"
                  value={inputText2}
                ></input>
              </div>
              <div className="text-sm w-full mt-4">
                {intl.formatMessage({ id: "tools.generator.selectTitleColor" })}
              </div>
              <div className="flex h-10 mb-3">
                {colorPallete.map(color => (
                  <label key={color} className="cursor-pointer p-1">
                    <input
                      onChange={e => changeCaptureTextColor(color)}
                      type="radio"
                      value={color}
                      className="hidden"
                      name="selectTextColor"
                    />
                    <div className="rounded-full border border-gray-400 hover:shadow">
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 200 200"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="100"
                          cy="100"
                          r="100"
                          style={{ fill: color }}
                        />
                      </svg>
                    </div>
                  </label>
                ))}
              </div>
              <button
                onClick={() => capture()}
                className="w-full h-12 cursor-pointer bg-teal-600 hover:bg-teal-500 text-teal-100 py-2 px-4 rounded inline-flex justify-center items-center border border-gray-400"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-current w-4 h-4 mr-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M16.21 16.95a5 5 0 0 1-4.02 4.9l-3.85.77a1 1 0 0 1-.9-.27l-.71-.7a2 2 0 0 1 0-2.83l1.44-1.45a13.17 13.17 0 0 1-1.42-1.41L5.31 17.4a2 2 0 0 1-2.83 0l-.7-.7a1 1 0 0 1-.28-.9l.77-3.86a5 5 0 0 1 4.9-4.02h.86a13.07 13.07 0 0 1 12.82-5.47 1 1 0 0 1 .83.83A12.98 12.98 0 0 1 16.2 16.1v.85zm-4.41 2.94a3 3 0 0 0 2.41-2.94v-1.4a1 1 0 0 1 .47-.84A11.04 11.04 0 0 0 19.8 4.33 10.98 10.98 0 0 0 9.42 9.45a1 1 0 0 1-.85.47h-1.4a3 3 0 0 0-2.94 2.4l-.66 3.34.33.33 2.24-2.24a1 1 0 0 1 1.52.12 11.08 11.08 0 0 0 2.6 2.6 1 1 0 0 1 .12 1.52l-2.24 2.24.33.33 3.33-.67zM15 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                </svg>
                <p>{intl.formatMessage({ id: "tools.generator.generate" })}</p>
              </button>
            </div>
          </div>
          <h3 className="font-semibold mb-3 text-lg leading-tight sm:leading-normal">
            {intl.formatMessage({ id: "tools.generator.how2use" })}
          </h3>
          <ul className="list-disc list-inside text-sm mb-3">
            <li>{intl.formatMessage({ id: "tools.generator.li1" })}</li>
            <li>{intl.formatMessage({ id: "tools.generator.li2" })}</li>
            <li>{intl.formatMessage({ id: "tools.generator.li3" })}</li>
            <li>{intl.formatMessage({ id: "tools.generator.li4" })}</li>
          </ul>
          <AdsenseResponsive />
        </div>
      </section>
    </Layout>
  )
}
export default FeaturedImageGenerator
